import request from '@/utils/request'
// const baseUrl = 'http://39.107.120.71:1997/ir/'
const baseUrl = 'https://state.renruikeji.cn/api/ir/'
const baseState = 'https://state.renruikeji.cn/api/userservice'
const erpUrl = 'https://erpback.renruikeji.cn/api'
// 获取企业微信userId
export function getQxUserId(data) {
    return request({
        url: '/group/login',
        method: 'post',
        params: data,
        baseURL: baseState
    })
}
// 获取企业微信 js sdk 配置信息
export function getJsSdkConfig(data) {
    return request({
        url: '/login/getAgentConfigById',
        method: 'post',
        params: data,
        baseURL: baseState
    })
}
// 获取密钥
export function getCosToken(data) {
    return request({
        url: '/erp/backend/bucket/getUrl',
        method: 'post',
        params: data,
        baseURL: erpUrl
    })
}
// 获取用户信息
export function getUserData(data) {
    return request({
        url: 'assess/getUserInfo/' + data.month,
        method: 'get',
        data,
        baseURL: baseUrl
    })
}
// 添加考核任务
export function addPerf(data) {
    return request({
        url: 'assess/add',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 更新考核任务
export function editPerf(data) {
    return request({
        url: 'assess/modify',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 提交考核任务
export function submitPerf(data) {
    return request({
        url: 'assess/submit',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 查看考核任务列表
export function getPerfList(data) {
    return request({
        url: 'assess/examineList',
        method: 'post',
        params: data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 根据id 获取考核详情
export function getPerfById(data) {
    return request({
        url: 'approval/getAssessInfo/' + data.id,
        method: 'get',
        params: data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 审批通过或驳回
export function modifyFlow(data) {
    return request({
        url: 'approval/modifyFlow',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 获取人事审批任务
export function hrGetList(data) {
    return request({
        url: 'assess/hrList',
        method: 'post',
        params: data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 评分
export function addAppraisal(data) {
    return request({
        url: 'assess/appraisal',
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 获取我的考核列表
export function myAppraisal(data) {
    return request({
        url: 'assess/selfList',
        method: 'post',
        params: data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}
// 判断是否已过填报日期
export function isDateIn(data) {
    return request({
        url: 'assess/checkOverTimes',
        method: 'get',
        params: data,
        headers: { 'Content-Type': 'application/json' },
        baseURL: baseUrl
    })
}


